<template>
  <label class="switch">
    <input :checked="on" type="checkbox" @change="onChange">
    <span ref="switcher" class="slider round bounce"></span>
  </label>
</template>

<script>
export default {
  name: "TheSwitch",
  props: {
      value: Boolean
  },
  data(){
    return {
      on: this.value
    }
  },
  methods: {
    onChange() {
      this.on = !this.on;
      if (this.on) {
        this.$refs.switcher.classList.add('final');
        this.$refs.switcher.classList.remove('start');
      } else {
        this.$refs.switcher.classList.add('start');
        this.$refs.switcher.classList.remove('final');
      }
      this.$emit('input', this.on)
    }
  }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
  top:7px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-image: url('../assets/switch_bg_beige.png');
}

.slider.final {
  background-image: url('../assets/switch_bg.png');
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 4px;
  bottom: 3px;
  background-color: #FFF;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.bounce:before {
  animation: bounce 2s ease 0s infinite;
  transform-origin: left;
}

.slider.final:before {
  animation: slide-anim .3s linear 0s 1 forwards;
}
.slider.start:before {
  animation:
    slide-anim-back .3s linear 0s 1 forwards,
    bounce 2s ease .8s infinite;
}

@media only screen and (max-width: 600px) {
  .switch {
    width: 50px;
    height: 14px;
    box-sizing: border-box;
    top:-2px;
  }

  .slider {
    width: auto;
    height: 20px;
  }

  .slider:before {
    height: 16px;
    width: 17px;
    left: 2px;
    bottom: 2px;
  }

  .bounce:before {
    animation: bounce-mobile 2s ease 0s infinite;
    transform-origin: left;
  }

  .slider.final:before {
    animation: slide-anim-mobile .3s linear 0s 1 forwards;
  }
  .slider.start:before {
    animation:
      slide-anim-back-mobile .3s linear 0s 1 forwards,
      bounce-mobile 2s ease .8s infinite;
  }
}

@keyframes slide-anim {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(28px);
  }
}

@keyframes slide-anim-back {
  from {
    transform: translateX(28px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 91%, 100% {transform: translateX(0);}
  40% {transform: translateX(9px);}
  70% {transform: translateX(5px);}
  86% {transform: translateX(2px);}
  96% {transform: translateX(1px);}
}

@keyframes slide-anim-mobile {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(17px);
  }
}

@keyframes slide-anim-back-mobile {
  from {
    transform: translateX(17px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes bounce-mobile {
  0%, 20%, 40%, 65%, 75%, 100% {transform: translateX(0);}
  30% {transform: translateX(17px);}
  60% {transform: translateX(13px);}
  70% {transform: translateX(6px);}
}

</style>
