import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
    config: { id: "G-ZD1NS3CZBH" }
}, router);

const EventBus = new Vue();
export default EventBus;


Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
