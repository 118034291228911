const LEASE_EXPIRATION = 10000;
export const LocalStorageMixin = {

    storeWithLease(name, val) {
        const payload =  {value: val, timestamp: new Date().getTime()};
        window.localStorage.setItem(name, JSON.stringify(payload))
    },

    getFromLease(name) {
        const payload = window.localStorage.getItem(name);
        const data = JSON.parse(payload);
        if(data && Object.prototype.hasOwnProperty.call(data, 'timestamp') && Object.prototype.hasOwnProperty.hasOwnProperty.call(data, 'value')) {
            const now = new Date().getTime();
            if (now - data.timestamp < LEASE_EXPIRATION) {
                return data.value;
            }
            else {
                data.value = 0;
                window.localStorage.setItem(name, JSON.stringify(data))
                console.warn("lease expired for %s", data);
            }
        } else {
            console.warn('Payload weird or expired %s', payload);
        }

        return null;
    }
}