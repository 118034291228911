import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import "@/assets/global.css"

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#122b3a',
                light: '#ddcba9',
                accent: '#dda538',
                secondary: '#00a3ac',
            },
            dark: {
                primary: '#002b3c',
                light: '#ddcba9',
                accent: '#ddcba9',
                secondary: '#001720',
            }
        }
    }
});
