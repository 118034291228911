<template>
  <v-app :mode="mode">
    <v-main>
      <v-container>
        <!-- If using vue-router -->
        <transition
            name="fade"
            mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
    <v-footer class="pl-8 pr-8" app>
      <v-row>
        <v-col cols="12" md="5" class="d-flex justify-center justify-md-start align-center">
          <router-link class="mr-4" to="/impressum">Impressum</router-link>
          <router-link to="datenschutz">Datenschutz</router-link>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-md-start justify-center align-center">
          <a href="https://www.instagram.com/ymdod/?hl=de" target="_blank">
            <v-img height="35px" contain src="@/assets/instagram.png"></v-img>
          </a>
          <a href="https://www.facebook.com/Cogrowing-GmbH-CoKG-101931935318041" target="_blank">
            <v-img height="35px" contain src="@/assets/facebook.png"></v-img>
          </a>
          <a href="https://wa.me/491725379733?text=Hallo%20Jungs,%20habt%20ihr%20Zeit%20für%20ein%20Kennenlernen%20und%20Austausch?" target="_blank">
            <v-img height="35px" contain src="@/assets/whatsapp_icon_SW.png"></v-img>
          </a>
        </v-col>
        <v-col cols="12" md="2" class="d-flex align-center justify-center justify-md-end font-weight-light">
          &copy;cogrowing {{new Date().getFullYear()}}
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import EventBus from "./main";

export default {
  name: 'App',

  components: {},
  data: () => ({
    mode: 'init',
  }),
  watch: {
    $route(to) {
      if (to.Name !== 'Home'){
        this.$vuetify.theme.dark = true;
      }
    }
  },
  mounted () {
    EventBus.$on('mode-changed', function (newMode) {
      this.mode = newMode;
    }.bind(this));
  },
  methods: {}
};
</script>
<!--suppress CssUnresolvedCustomProperty -->
<style>
:root {
  --app-primary-color: var(--v-primary-base);
  --app-secondary-color: var(--v-secondary-base);
  --app-light-color: var(--v-light-base);
  --app-dark-color: var(--v-dark-base);
  --app-secondary-light-color: var(--v-secondaryLight-base);
  --app-secondary-dark-color: var(--v-secondaryDark-base);
  --app-font-family: Tahoma, Helvetica, Arial, Verdana, sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.ch2-icon-right.ch2-icon.ch2-visible {
  display: none;
}

.imprint .ch2-icon-right.ch2-icon.ch2-visible,
.privacy .ch2-icon-right.ch2-icon.ch2-visible {
  display: block;
}
</style>
