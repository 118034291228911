<template>
  <div>
    <div class="d-flex justify-center align-center header" :class="{sticky: !off && toggleCount > 1}">
      <TheSwitch class="switch" v-model="toggle" @input="increase"></TheSwitch>
      <span class="logo">growing</span>
    </div>
    <v-fade-transition>
      <div v-show="mode === 'VIDEO'">
        <Video id="video" :mp4="videoSource"></Video>
        <div class="d-flex align-center justify-center">
          <v-btn class="cta" rounded to="/about" >Neugierig?</v-btn>

          <!--          <v-dialog-->
<!--              v-model="dialog"-->
<!--              max-width="80vw">-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-btn class="cta" rounded v-on="on" v-bind="attrs">Neugierig?</v-btn>-->
<!--            </template>-->
<!--            <VideoPopOver>-->
<!--              <v-btn-->
<!--                  rounded-->
<!--                  class="reject"-->
<!--                  @click="dialog = false">-->
<!--                No you won't-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                  rounded-->
<!--                  class="confirm"-->
<!--                  @click="dialog = false">-->
<!--                Damn right!-->
<!--              </v-btn>-->
<!--            </VideoPopOver>-->
<!--          </v-dialog>-->
        </div>
      </div>
    </v-fade-transition>
    <v-slide-y-reverse-transition>
      <v-sheet id="info" elevation="3" v-show="mode === 'INFO' || mode === ''" class="pa-2">
        <div>
          <h1>Let's highlight <br/> something</h1>
          <TextBox1 lines="35"></TextBox1>
        </div>
        <div>
          <h1>Call @ymdod</h1>
          <TextBox2 lines="35"></TextBox2>
        </div>
      </v-sheet>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
import {LocalStorageMixin} from '@/mixins/LocalStorageMixin';
import TextBox1 from '@/components/TextBox1';
import TextBox2 from '@/components/TextBox2';
import Video from '@/components/Video';
import TheSwitch from '@/components/TheSwitch';
import EventBus from '../main';

const TOGGLE_COUNT = 'toggle_count';
export default {
  name: 'Home',
  components: { TheSwitch, Video, TextBox1, TextBox2},
  mixins: [LocalStorageMixin],
  data() {
    return {
      toggleCount: 0,
      off: true,
      toggle: false,
      dialog: false,
      videos: [
        '/V1_Cogrowing_Panda.mp4',
        '/V2_Cogrowing_Timelapse.mp4',
        '/V3_Cogrowing_OfficeTime.mp4',
      ]
    }
  },
  mounted() {
  },
  created() {
  },
  computed: {
    mode() {
      if (this.off) {
        EventBus.$emit('mode-changed', 'init');
        return '';
      } else if (this.toggleCount === 1) {
        EventBus.$emit('mode-changed', 'info');
        return 'INFO';
      } else {
        EventBus.$emit('mode-changed', 'video');
        return 'VIDEO'
      }
    },

    videoSource() {
      const idx = this.toggleCount % 3;
      return this.videos[idx];
    }
  },
  methods: {
    increase(e) {
      this.off = !e;
      if (e) {
        let oldCount = LocalStorageMixin.getFromLease(TOGGLE_COUNT) || this.toggleCount;
        this.toggleCount = oldCount + 1;
        LocalStorageMixin.storeWithLease(TOGGLE_COUNT, this.toggleCount);
      }

      if (this.toggleCount === 0 || this.off) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }

    }

  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 30vh;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  transition: all 400ms ease-in-out;
}

.switch {
  transform: scale(2, 2);
}

#info {
  position: relative;
  /*bottom: -50vh;*/
  margin-top: 70vh;
  padding-bottom: 5em !important;
  background-color: var(--app-secondary-color);
  display: block;
  overflow: hidden;
}

#info, .v-card__text {
  color: var(--app-light-color) !important;
}

.header {
  transition: all 400ms linear !important;
  transform: translateY(0);
}

.sticky.header {
  z-index: 10;
}

.sticky span, .sticky .switch {
}

@media screen and (max-width: 1100px) {

  #info {
    margin-top: 50vh;
  }
}

</style>
