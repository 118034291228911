<template>
  <div>
    <h2>Marketing geht leichter, wenn man es gemeinsam anpackt</h2>
    <p>Deshalb gibt es seit Oktober 2020 uns: cogrowing. Ein Teamplayer, der sich dynamisch einklinkt, Marketingprojekte verantwortet und der sich nach der Umsetzung auch zeitweise wieder ausklinken kann – also ganz nach Bedarf. Dabei ist ein Projekt für uns immer mehr als nur ein abstraktes Konstrukt aus Zuständen, Zielen und Zahlen: Es sind die Menschen. Nur wenn wir sie mit ins Boot holen, können wir etwas bewirken, was ein Projekt erfolgreich macht und nachhaltiges Wachstum erzielt.</p>
    <br/>
    
    <h2>Wir sind erschreckend optimistisch</h2>
    <p>Wir sind die, die immer einen Weg finden. Wir behalten den Überblick und einen kühlen Kopf, auch wenn es mal zu brennen anfängt. Wir sind die Unkonventionellen und stellen auch mal Fragen, die vielleicht wehtun. Aber am wichtigsten: Wir suchen immer nach Lösungen auch manchmal fernab vom Standard, vor allem wenn man damit ein Problem effizienter oder schneller lösen kann. Hauptsache Herausforderungen werden gemeistert und der Spaß an der Sache bleibt dabei nicht auf der Strecke.</p>
    <br/>
    
    <h2>Die Sache mit der Digitalisierung</h2>
    <p>Die letzten Jahre haben wir viel Zeit mit Projekten rund um Digitalisierung, Change Management und den Aufbau von neuen Marken verbracht. Ganz egal, ob wir unsere Köpfe in komplexe technische Systeme der Automobilbranche, in viele schöne Fernweh-Momente der Tourismusbranche oder in Start-ups gesteckt haben, die sich mit dem Verleih von Luxusprodukten einen Namen gemacht haben – wir sind stolz drauf, die eine oder andere verrückte Idee mit unseren Kunden umgesetzt zu haben.</p>
    <br/>
  </div>
</template>

<script>
export default {
  name: "TextBox1",
  props: {
    lines: String
  },
  data(){
    return {
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
