<template>
  <div>
    <h2>Your Marketing Dudes On Demand</h2>
    <p>Cogrowing vereint die strategische Exzellenz einer Unternehmensberatung mit der Agilität und Kreativität einer Agentur. Als Teamplayer treten wir gemeinsam an: gegen Mangel an Wissen und Zeit bei der Realisierung von Marketingprojekten, gegen Orientierungslosigkeit bei der Marketingstrategie. Wir sorgen für Qualität, Effektivität und Effizienz in der Markenkommunikation und Produktentwicklung. Und wenn uns eine Expertise fehlt, dann haben wir immer noch ein paar weitere Dudes im Ärmel.</p>
  <br/>
    <div>
    <v-btn rounded style="background-color: #dda538" href="mailto:info@cogrowing.io">Just call @ymdod</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextBox2",
  props: {
    lines: String
  },
  data(){
    return {
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
