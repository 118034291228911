<template>
  <div>
    <video id="vid" autoplay loop muted webkit-playsinline="true" playsinline="true">
      <source :src="mp4" type="video/mp4">
    </video>
  </div>
</template>
<script>
export default {
  name: "Video",
  props: {
    mp4: String
  },
  watch: {
    mp4: function (){
      const video = document.getElementById('vid');
      video.controls = false;
      video.load();
    }
  }
}
</script>
<style>
#vid {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>